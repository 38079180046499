.custom-cursor {
  position: absolute;
  width: 1000px;
  height: 1000px;
  background-color: rgba(255, 255, 255, 0.01);
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 0;
  backdrop-filter: blur(10px);
  background: radial-gradient(circle, rgba(35, 35, 255, 0.1) 0%, rgba(255, 255, 255, 0) 70%);
  transition: all 150ms ease;
}
